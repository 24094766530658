import "./App.css";
import Home from "./views/pages/Home";
import Dashboard1 from "./views/components/DashboardComponents/Dashboard1/Dashboard1";
import Dashboard2 from "./views/components/DashboardComponents/Dashboard2/Dashboard2";
import Dashboard3 from "./views/components/DashboardComponents/Dashboard3/Dashboard3";
import Dashboard from "./views/pages/Dashboard";
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import Info1 from "./views/components/InfoComponents/Info1/Info1";
import Info2 from "./views/components/InfoComponents/Info2/Info2";
import Info3 from "./views/components/InfoComponents/Info3/Info3";

function App() {
  return (
    <div className="App">
     <Router>
     <Switch>
     <Route path="/dashboard1" exact>
         <Dashboard1/>
       </Route>
       <Route path="/dashboard2" exact>
         <Dashboard2/>
       </Route>
       <Route path="/dashboard3" exact>
         <Dashboard3/>
       </Route>
     <Route path="/dashboard" exact>
         <Dashboard1/>
       </Route>

       <Route path="/Info1" exact>
         <Info1/>
       </Route>
       <Route path="/Info2" exact>
         <Info2/>
       </Route>
       <Route path="/Info3" exact>
         <Info3/>
       </Route>
       <Route path="/Info" exact>
         <Info1/>
       </Route>
       <Route path="/" exact>
         <Home/>
       </Route>
       <Route>
       <Redirect to="/404" />
          <Home/>
       </Route>
       
       {/*<Route path="*" component={NotFoundPage} />
       </Route>*/}
      
     </Switch>
     </Router>

    </div>
  );
}

export default App;
