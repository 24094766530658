import { DiscFullRounded, Reddit, Telegram } from "@material-ui/icons";
import React from "react";
import "../../../assets/css/Footer.css";
import discord from "../../../assets/img/discord.svg";

function Footer() {
  return (
    <div className="footer">
      <a href="https://discord.com/channels/417762285172555786/818997782966566943"  target="_blank">
      <div className="footer__discord cursor">
        <img src={discord} alt="" />
      </div>
      </a>

     {/*} <a href="https://www.reddit.com/" target="_blank">
        <div className="footer__reddit cursor">
          <Reddit />
        </div>
  </a>*/}

     <a href="https://t.me/radixStakerDelegator" target="_blank">
     <div className="footer__telegram cursor">
        <Telegram />
      </div>
     </a>
    </div>
  );
}

export default Footer;
