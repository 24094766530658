import React from 'react'
import { Link } from 'react-router-dom'
import '../../../assets/css/DashboardHeader.css'

function DashboardHeader({dashboard1, dashboard2, dashboard3}) {
    return (
        <div className="dashboardHeader">
          
          <div className="dashboard1">
          <Link style={{textDecoration:'none', color: 'black', fontWeight:dashboard1?'bold':''}} to="/dashboard1">
               Radix
               </Link>
           </div>
        

           <div className="dashboard2">
           <Link style={{textDecoration:'none', color: 'black', fontWeight:dashboard2?'bold':''}} to="/dashboard2">
               Containers
           </Link>
           </div>

           <div className="dashboard3">
           <Link style={{textDecoration:'none', color: 'black', fontWeight:dashboard3?'bold':''}} to="/dashboard3">
               Hosting
           </Link>
           </div>

            
        </div>
    )
}

export default DashboardHeader
