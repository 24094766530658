import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import InfoHeader from '../../InfoHeader/InfoHeader';
import Header from '../../HomeComponents/Header';
import '../../../../assets/css/DashboardBody.css';

function Info2() {
    const [showContainers, setShowContainers]= useState(true)
    return (
        <div  style={{maxHeight: '100vh', overflow:'hidden'}} className="info2">
             <Header info={true} />
            <InfoHeader info2={true}/>
      <p>These are links</p>
        </div>
    )
}

export default Info2
