import React from 'react'
import { Link } from 'react-router-dom'
import '../../../assets/css/DashboardHeader.css'

function InfoHeader({info1, info2, info3}) {
    return (
        <div className="infoHeader">
          
          <div className="info1">
          <Link style={{textDecoration:'none', color: 'black', fontWeight:info1?'bold':''}} to="/info1">
               FAQs
               </Link>
           </div>
        

           <div className="info2">
           <Link style={{textDecoration:'none', color: 'black', fontWeight:info2?'bold':''}} to="/info3">
           About Us  
           </Link>
           </div>

           <div className="info3">
           <Link style={{textDecoration:'none', color: 'black', fontWeight:info3?'bold':''}} to="/info3">
              
           </Link>
           </div>

            
        </div>
    )
}

export default InfoHeader
