import React from "react";
import Header from "../components/HomeComponents/Header";
import Body from "../components/DashboardComponents/Body";
import "../../assets/css/home.css";
import DashboardHeader from "../components/DashboardHeader/DashboardHeader";

function Dashboard() {
  return (
    <div  className="Dashboard">
      <Header dashboard={true} />
      <DashboardHeader/>
      <Body />
    </div>
  );
}

export default Dashboard;
