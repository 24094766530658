import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import InfoHeader from '../../InfoHeader/InfoHeader';
import Header from '../../HomeComponents/Header';
import '../../../../assets/css/DashboardBody.css';
import '../../../../assets/css/Info.css';


function Info3() {
  const [showExporter, setShowExporter]= useState(true)
    return (
        <div  style={{maxHeight: '100vh', overflow:'auto'}} className="info3">
            <Header info={true} />
            <InfoHeader info3={true} />
            <div className="info">
    
                <div class="aboutUsText">
                    <p>
                <div class="aboutUsHeader">Our Fee Structure</div>
                    
                    <br></br>
                    RadixStaker is currently charging 1.99% fee for its validator services. There is a guaranteed MAXIMUM FEE for the duration of Olympia of 3%, if opportunity allows (ie the price of XRD rises) then we will review options to reduce this fee even further, but we guarantee it will not be above 3%. This fee is purely to help cover the cost of Azure cloud hosting services and ongoing support.
                    </p>
                    <br></br>
                    <br></br>
                    <div class="aboutUsHeader">Our Node Offerring</div>
                    <br></br>
                    <p>The RadixStaker node is hosted in Azure's Western Europe region and runs in Docker containers hosted on a D4s v3 (4 vcpus, 16 GiB memory) security hardened Linux VM. 
                    </p>
                    <br></br>
                    <p>
                    Metrics are exposed by Prometheus to Grafana which provides various observability dashboards some of which are shared with you on our Dashboards page. Thresholds are configured against baseline performance and any deviation is alerted through Alertmanager to our team who are available 24x7 to ensure systems remain healthy and to take remedial action if required. 
                    </p>
                    <br></br>
                    <p>
                    A second live node instance runs in Azure's UK South region, ready to assume the role of RadixStaker validator in the event of any issues. This is line with the Radix team's guidance for <a href="https://docs.radixdlt.com/main/node/maintaining-uptime.html" target="_blank">redundant validator</a> provisioning.
                    </p>
                    <br></br>
                <div class="aboutUsHeader">Our Team</div>

                    <br></br>
                    <p>The RadixStaker team has 70+ years combined IT experience in the fields of infrastructure delivery, IT security and project management in financial services, insurance, government and retail sectors. 
                    </p>
                    <br></br>
                    <p>The main team is formed by two long term friends, Greg (an Australian) and Travis (a South African) who met whilst working in the same UK company. Their relationship is 17 years young and their diverse IT skillsets and strong interest in crypto and the Radix project naturally led to a partnership to run a validator node for Radix <a href="https://www.radixdlt.com/post/radix-olympia-mainnet-is-here" target="_blank">Mainnet</a>. 
                    </p>
                    <br></br>
                    <p>They are excited to be part of such an ambitious and well executed project and relish the opportunity to support the growth of Radix through the provison of Radix node running services.
                    </p>
                    <br></br>
                    <p>A third member of the team (Gareth) is based in Australia and is on hand to help avoid sleepless nights and provide follow the sun incident response.
                    </p>
                    <br></br>
                    <br></br>
                </div>
            </div>
        </div>
    )
}

export default Info3
