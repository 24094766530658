import React from "react";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import "../../../assets/css/Header.css";
import RadixStakerLogo from '../../../assets/img/RadixStakerHeader.png'
import RadixStakerPop from '../../../assets/img/radixstaker.png'
import RadixSmall from '../../../assets/img/small.png'
import { Link } from "react-router-dom";
import DashboardHeader from "../InfoHeader/InfoHeader";
import useMediaQuery from "@material-ui/core/useMediaQuery";


function Header({home, dashboard, info}) {
  const matches = useMediaQuery("(max-width: 600px)");

  return (
    
      <div className="header-bottom">
        <nav>
          <li className={`${home?"active-link":""}`}>
         
            <Link to="/">
            HOME
            </Link>
          </li>
          <li className={`${dashboard?"active-link":""}`}>
          
            <Link to="/dashboard">      
            DASHBOARD
            </Link>
           
          </li>

          <li className={`${info?"active-link":""}`}>
            <Link to="/info">
            INFO
            </Link>
          </li>

         {
           (dashboard || info )?
           (
            <div className="logo">
            <img src={matches?RadixSmall:RadixStakerPop} width={matches?`80px`:'150px'} alt="" />
          </div>
           )
           :""
         }
        </nav>
      </div>
    
  );
}

export default Header;
