import React from "react";
import "../../../assets/css/TextBlock.css";

function TextBlock({ heading, description, Icon }) {
  return (
    <div className="textBlock">
      <div className="textBlock__icon">
        {Icon}
      </div>

      <div className="textBlock__right">
        <div className="textBlock__heading">{heading}</div>
        <div className="textBlock__desc">{description}</div>
      </div>
    </div>
  );
}

export default TextBlock;
