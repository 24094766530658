import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DashboardHeader from '../../DashboardHeader/DashboardHeader';
import Header from '../../HomeComponents/Header';
import '../../../../assets/css/Body.css'
import '../../../../assets/css/DashboardBody.css';


function Dashboard1() {
    const [showInfrastructure, setShowInfrastructure]= useState(true)

    return (
        <div style={{maxHeight: '100vh', overflow:'hidden'}} className="dashboard1">
             <Header dashboard={true} />
            <DashboardHeader dashboard1={true}/>
        <div  style={{maxHeight: '100vh', overflow:'auto'}} className="dashboard">   
          <embed style={{display:showInfrastructure?`block`:`none`}} type="text/html" src="https://grafana.radixstaker.com:3000/d/RadixDashboard/radix-node-dashboard?orgId=1&refresh=5s&theme=light&kiosk" width="100%" height="1850"></embed>
        
        </div>
        </div>
    )
}

export default Dashboard1
