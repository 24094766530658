import React, { useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import InfoHeader from "../../InfoHeader/InfoHeader";
import Header from "../../HomeComponents/Header";
import "../../../../assets/css/Body.css";
import "../../../../assets/css/Info.css";
import radixWalletViewValidators from "../../../../assets/img/radixWalletViewValidators.png";
import telegram from "../../../../assets/img/telegram.png";

function Info1() {
  const [showInfrastructure, setShowInfrastructure] = useState(true);

 const handleOnExpand=(id)=>{
    var element= document.getElementById(id)
            element.classList.toggle("active");
          if (element.style.display === "block") {
            element.style.display = "none";
          } else {
            element.style.display = "block";
          }  
  }
  return (
    
    <div className="info1">
      
      <Header info={true} />
      <InfoHeader Info1={true} />
      <div className="info">
         <div className="collapsible-header">
           <div>
              <div onClick={()=>{handleOnExpand("content1")}} class="collapsible">
                What is in the Olympia Mainnet?
              </div>
           <div id="content1" class="textLeft">
                <div className="listItemContent" >
                  <p>
                    Radix is the first layer-one protocol specifically built to serve DeFi and Olympia is its first production release. {<a href="https://learn.radixdlt.com/article/what-is-being-delivered-for-the-olympia-mainnet" target="_blank">Olympia</a>} delivers the first iteration of the Radix engine. The next release Alexandria (planned for end of 2021) will bring smart contract capabiities to Radix. Check of the Radix {<a href="https://www.radixdlt.com/post/radix-2021-roadmap" target="_blank"> roadmap</a>} for more information on upcoming development releases.
                  </p>
                  <br/>
                </div>
           </div>
         </div>
            
          <div>
          <div onClick={()=>{handleOnExpand("content2")}} class="collapsible">
          How do I earn earn rewards by staking?
            </div>
 
            <div id="content2" class="textLeft">
                <div className="listItemContent" >
                <p>
                Radix is a proof of stake blockchain. Owners of the Radix token can stake their XRD tokens with Radix validator nodes and in the process earn rewards and help secure the Radix network. In return for delegating your tokens to a validator the Radix protocol automatically creates network emmissions that are returned to the wallet from which the delgation occurs. Currently these rewards are estimated to be ~30% APY. You can read more about staking {<a href="https://www.radixdlt.com/post/radix-staking-and-incentive-rewards-guide" target="_blank">here.</a>} 
                </p>
                <br />
                </div>
            </div>
          

          </div>
           
            <div>
               <div onClick={()=>{handleOnExpand("content3")}} class="collapsible">
                  How do I choose a validator to stake to?
               </div>
            </div> 

            <div id="content3" class="textLeft">
                <div className="listItemContent" >
                   <p>
                      Which validator(s) you stake to should involve careful consideration of these factors: 
                     <div className="listItemContent" >
                        <ul>
                            <li>Emissions are only awarded to the top 100 validators in the network.</li>
                            <li>Emissions are reduced when a validator is not available and cannot participate in network consensus during an epoch and your share of rewards will be impacted.</li>
                            <li>Running a validator node is an expensive endeavour with average hosting costs in the big 3 cloud providers of ~$500 per month, this is before considering the support staff effort. Any node runner running at zero fees may not do so for long so be wary.</li>
                            <li>Anyone running a validator should have skin in the game. Look for how much "owner stake" each validator has committed as their rewards are impacted the same as a delegators for low availability</li>
                            <li>Spread your delegations amongst more than one (the Radix team suggests 5) validator to spread your risk of lost emissions.</li>
                        </ul>
                      </div>
                      How you delegate your XRD will have an effect on the rewards you earn and the overall security of the network, so choose wisely. Check out the Radix teams {<a href="https://www.radixdlt.com/post/radix-staking-and-incentive-rewards-guide" target="_blank">delegation guide</a>} for more information.      
                   </p>
                   <br />
                </div>
            </div>
          


  <div>
            <div onClick={()=>{handleOnExpand("content4")}} class="collapsible">
            Where can I can see a list of validators?
            </div>
            

            <div id="content4" class="textLeft">
                <div className="listItemContent" >
                <p>
                The Radix wallet has a link to the Radix explorer {<a href="https://explorer.radixdlt.com/#/validators" target="_blank">validator dashboard</a>} in the Stake & Unstake section of the wallet. This dashboard shows the validators in rank order based on total stake.  
                </p>
                <br />
                </div>
            </div>

            </div> 


      <div>
            <div onClick={()=>{handleOnExpand("content5")}} class="collapsible">
            Where can I download the latest radix wallet?
            </div>
            

            <div id="content5" class="textLeft">
                <div className="listItemContent" >
                <p>
                The latest Radix wallet can be downloaded {<a href="http://wallet.radixdlt.com/" target="_blank">here</a>}.   
                </p>
                <br />
                </div>
            </div>

            </div> 


            <div>
            <div onClick={()=>{handleOnExpand("content8")}} class="collapsible">
            Can I use my Ledger S to store Radix tokens?
            </div>
            

            <div id="content8" class="textLeft">
                <div className="listItemContent" >
                <p>
                The Radix team are still working with the owners of ledger to natively support storing Radix tokens on Ledger. However it is still possible to store your Radix on Ledger S, but you do need to side load the radix app onto the Ledger S. The Radix team have created some very good instructions for your appropriate desktop operating system <a href="https://docs.radixdlt.com/main/user-applications/ledger-app-sideload.html" target="_blank">here</a>.   
                </p>
                <br />
                </div>
            </div>

            </div> 



                <div>
            <div onClick={()=>{handleOnExpand("content6")}} class="collapsible">
            How do I transfer my tokens from eXRD to Radix?
            </div>

            <div id="content6" class="textLeft">
                <div className="listItemContent" >
                <p>
                   Before tokens can be used they must be transferred from the Ethernet ledger based eXRD to the Radix native blockchain token XRD. This is completed through {<a href="https://www.instabridge.io/" target="_blank">Instabridge</a>} which is scheduled for release on the 6th of August. Details of how to complete the transfer wil be shared on launch.    
                </p>
                <br />
                </div>
            </div>

            </div> 

            <div onClick={()=>{handleOnExpand("content7")}} class="collapsible">
            Any other questions? Get in touch.
            </div>
 
            <div id="content7" class="textLeft">
                <div className="listItemContent" >
                <p>
                I you have any questions about Radix or our validator offerring please reach out via our  {<a href="https://t.me/radixStakerDelegator" target="_blank">Telegram group</a>}   
                </p>
                <br />
                </div>
            </div>
    
        </div>
      </div>
    </div>
  );
}

export default Info1;
