import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DashboardHeader from '../../DashboardHeader/DashboardHeader';
import Header from '../../HomeComponents/Header';
import '../../../../assets/css/DashboardBody.css';

function Dashboard2() {
    const [showContainers, setShowContainers]= useState(true)
    return (
        <div  style={{maxHeight: '100vh', overflow:'hidden'}} className="dashboard2">
             <Header dashboard={true} />
            <DashboardHeader dashboard2={true}/>
        <div  style={{maxHeight: '100vh', overflow:'auto'}} className="dashboard">
     
        <embed style={{display:showContainers?`block`:`none`}} type="text/html" src="https://grafana.radixstaker.com:3000/d/DockerContainers/docker-containers?orgId=1&refresh=10s&from=now-12h&to=now&theme=light&kiosk" width="100%" height="2000"></embed>
        </div>
        </div>
    )
}

export default Dashboard2
