import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DashboardHeader from '../../DashboardHeader/DashboardHeader';
import Header from '../../HomeComponents/Header';
import '../../../../assets/css/DashboardBody.css';

function Dashboard3() {
  const [showExporter, setShowExporter]= useState(true)
    return (
        <div  style={{maxHeight: '100vh', overflow:'hidden'}} className="dashboard3">
            <Header dashboard={true} />
            <DashboardHeader dashboard3={true} />
        <div  style={{maxHeight: '100vh', overflow:'auto'}} className="dashboard">    
    <embed style={{display:showExporter?`block`:`none`}} type="text/html" src="https://grafana.radixstaker.com:3000/d/DockerHost/docker-host?orgId=1&refresh=10s&from=now-12h&to=now&theme=light&kiosk" width="100%" height="1750"></embed>    
        </div>
        </div>
    )
}

export default Dashboard3
