import React from "react";
import Header from "../components/HomeComponents/Header";
import Body from "../components/HomeComponents/Body";
import "../../assets/css/home.css";
import TextBlock from "../components/TextBlock/TextBlock";
import { DnsOutlined } from "@material-ui/icons";
import SignalCellularAltOutlinedIcon from '@material-ui/icons/SignalCellularAltOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';

import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import UpdateIcon from '@material-ui/icons/Update';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import PolicyIcon from '@material-ui/icons/Policy';
import ForumIcon from '@material-ui/icons/Forum';

import '../../assets/css/Header.css'
import Footer from "../components/Footer/Footer";

function Home() {
  return (
    <div className="Home">
      <Header home={true} />
      <Body />
      
      <div className="textBlock__wrapper">
      <TextBlock Icon={<CheckCircle/>} heading="Guaranteed Fee Structure" description="Running a successful Validator incurs costs for infrastructure hosting and support. We will charge a small fee to cover these costs, currently 1.99%. However, we are committed to not increasing this fee above 3% for the duration of Olympia. This is our firm commmitment and stake our reputation on it!"/>
           
      <TextBlock Icon={<UpdateIcon/>} heading="Dedicated Support Team" description="A team of highly skilled support profressionals are on hand 24x7. Observability tools provide support staff with key performance metrics and any variation against baseline performance. Any issues with Docker containers or hosting infrastructure are alerted for support staff to take remedial action."/>
      
      <TextBlock Icon={<CloudDoneIcon/>} heading="Secure Cloud Hosting" description="Hosted in Azure, infastructure is hardened to standards defined by the National Institute of Standards and Technology. Firewalls are in place on the hosting infrastructure and within Azure to reduce the attack surface for malicious actors and suspicious activity is monitored for and alerted."/>

      <TextBlock Icon={<VerifiedUserOutlinedIcon/>} heading="Highly Available Infrastructure" description="RadixStaker's primary node is hosted in Azure's Wesern Europe datacenter. In the case of server issues or the unlikely event of an Azure region failure, a second validator node runs in UK South ready to assume the identity of the RadixStaker primary validator node. This ensures our validator node conntinues to particpate in consensus and earn rewards in the event of issues."/>

      <TextBlock Icon={<PolicyIcon/>} heading="Monitoring & Obvservability" description={<span>
        All infrastructure and application components are monitored using Prometheus. Alerting is configured to notify support staff of any issues. In the interest of transparency all <a href="../dashboard1/" target="_top">Radix node</a>, <a href="../dashboard2/" target="_top">Docker containers</a> and <a href="../dashboard3/" target="_top">Hosting infrastructure</a> performance metrics are are available for you to observe on the <a href="../dashboard/" target="_top">Dashboard</a> page.
      </span>}/>
      
      <TextBlock Icon={<ForumIcon/>} heading="Active Community Member" description={<span>
	  We are one of the noderunners selected to participate in Radix's highly successful Betanet and Stokenet testing phases. You will find us in the Radix 
	  <a href="https://discord.com/channels/417762285172555786/818997782966566943"  target="_blank"> Discord</a> actively engaging with the community. We also host a <a href="https://t.me/radixStakerDelegator" target="_blank">Telegram channel</a> to share updates and for our delegators to ask any questions.
      </span>}/>
      </div>

      <Footer/>
    </div>
  );
}

export default Home;
